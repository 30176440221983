<script setup>
  const { data } = defineProps(['data']);
  const { title, label, body_left, body_right, divider } = data;
</script>

<!----------------------------------------------------------------------------->

<template>
  <Section sectionID="vision" backgroundTab="top">
    <div class="content">
      <h2>{{ title }}</h2>
      <div class="text">
        <Brackets v-if="label" class="subtitle"
          ><h3>{{ label }}</h3></Brackets
        >
        <div class="body" v-html="body_left" />
        <div class="body" v-html="body_right" />
      </div>
    </div>
    <div class="divider">{{ divider }}</div>
  </Section>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  section {
    padding: px(108) 0 px(72);

    .content {
      @include break-min-large {
        @include column(2, 9);
      }

      display: flex;
      flex-direction: column;

      gap: px(45);

      h2 {
        color: $white;
      }

      .text {
        display: flex;
        gap: px(24);

        .subtitle {
          position: absolute;
          transform: translate(calc(-100% - px(80)), 0);
        }

        .body {
          display: flex;
          flex-direction: column;

          gap: 1.5em;
        }
      }
    }

    .divider {
      @include roboto-mono-medium(px(12));

      display: flex;
      justify-content: stretch;
      align-items: center;
      gap: px(24);

      white-space: nowrap;

      margin-top: px(74);

      &:after,
      &:before {
        content: '';
        // position: absolute;

        width: 100%;
        height: px(1);
        background-color: $white;
      }
    }

    @include break-large {
      .content {
        .text {
          .subtitle {
            display: none;
          }
        }
      }
    }

    @include break-medium {
      padding: px(96) 0 px(20);

      .content {
        gap: px(24);
      }
    }

    @include break-small {
      padding: px(80) 0 px(20);
      .content {
        flex-direction: column;
        text-align: center;
        align-items: center;

        .text {
          flex-direction: column;
        }

        h2 {
          text-align: center;
          max-width: px(340);
        }

        .body {
          max-width: px(340);
        }
      }

      .divider {
        &:before,
        &:after {
          background-color: $dark-grey;
        }
      }
    }
  }
</style>
