<script setup>
  const { data } = defineProps(['data']);
  const { image } = data;
</script>

<!----------------------------------------------------------------------------->

<template>
  <Section>
    <Picture preset="landscape-1280" :image="image" />
  </Section>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  section {
    // --
  }
</style>
