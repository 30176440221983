<script setup>
  const { data } = defineProps(['data']);
  const { title, label, body, helmets } = data;

  const shuffleHelmets = ref();
  onMounted(() => {
    shuffleHelmets.value = helmets
      .concat()
      .sort(() => Math.random() - 0.5)
      .slice(0, 3);
  });
</script>

<!----------------------------------------------------------------------------->

<template>
  <Section sectionID="avatar" backgroundTab="top">
    <div class="content">
      <Text :title="title" :body="body" class="text" wide />
      <AvatarCard :label="label" :avatars="shuffleHelmets" class="avatar-card" />
    </div>
  </Section>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  section {
    padding: px(108) 0 px(72);

    .content {
      @include break-min-large {
        @include column(2, 10);
      }

      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      gap: px(32);

      @include break-min-small {
        :deep(.text) {
          min-width: px(420);
        }
      }
    }

    .text {
      @include break-max {
        max-width: px(364);
      }
    }

    .avatar-card {
      margin-top: -px(338);
    }

    @include break-large {
      .content {
        @include column(1, 10);

        :deep(.text) {
          min-width: 0;
        }
      }
    }

    @include break-medium {
      .content {
        flex-direction: column;
        align-items: center;
        gap: 8vw;

        .avatar-card {
          margin-top: 0;
          // align-self: flex-end;
        }

        :deep(.text) {
          max-width: px(500);
          text-align: center;
        }
      }
    }

    @include break-small {
      padding: px(72) 0 px(40);
      .content {
        // align-items: center;
      }
    }
  }
</style>
