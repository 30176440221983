<template>
  <svg
    width="16"
    height="11"
    viewBox="0 0 16 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.6026 0.410093C15.928 0.73553 15.928 1.26317 15.6026 1.5886L6.43594 10.7553C6.1105 11.0807 5.58286 11.0807 5.25742 10.7553L1.09076 6.5886C0.76532 6.26317 0.76532 5.73553 1.09076 5.41009C1.41619 5.08466 1.94383 5.08466 2.26927 5.41009L5.84668 8.9875L14.4241 0.410093C14.7495 0.0846564 15.2772 0.0846564 15.6026 0.410093Z"
      fill="#151515"
    />
  </svg>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  svg {
    width: px(16);
    height: px(11);
  }
</style>
