<script setup>
  const { data } = defineProps(['data']);
  const { subtitle: label, title, body, image } = data;
</script>

<!----------------------------------------------------------------------------->

<template>
  <Section sectionID="wallet">
    <div class="content">
      <Text narrow :title="title" :subtitle="subtitle" :body="body" />
      <img v-if="image" :src="useCdn().generatePicture(image, 'aside').imageUrl" />
    </div>
    <template v-slot:root
      ><div class="background" />
      <div class="background background-bottom" />
    </template>
  </Section>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  section {
    padding: px(52) 0 px(70);

    .content {
      @include column(2, 9);

      display: flex;
      justify-content: space-between;
      align-items: center;

      gap: px(32);

      img {
        width: auto;
        height: 34vw;
      }
    }

    .background {
      position: absolute;

      width: 100%;
      height: 100%;

      top: 0;

      background-color: $black-1;
      clip-path: polygon(0% 0%, 62% 0%, 38% 100%, 0% 100%);

      &-bottom {
        display: none;
      }
    }

    @include break-large {
      .content {
        @include column(1, 9);
      }
    }

    @include break-small {
      padding: px(20) 0 px(160);

      .content {
        @include column(1, 10);
        flex-direction: column;

        gap: px(72);

        img {
          width: 100%;
          height: auto;

          max-width: px(220);
        }
      }

      .background {
        background-color: $black;
        clip-path: polygon(0% 0%, 100% 0%, 100% 26%, 0% 38%);

        &-bottom {
          display: block;
          clip-path: polygon(0% 100%, 100% 88%, 100% 100%, 0% 100%);
        }
      }
    }
  }
</style>
