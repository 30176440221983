<script setup>
  import gsap from 'gsap';

  // --
  const { items } = defineProps(['items']);

  const bars = ref();

  const emit = defineEmits(['select']);
  const index = ref(0);

  // --
  function select(i, force) {
    if (index.value === i && !force) return;

    gsap.killTweensOf(bars.value[index.value]);
    bars.value[index.value].style.transform = '';

    index.value = i;

    gsap.fromTo(
      bars.value[index.value],
      { scaleX: 0.0 },
      {
        duration: 6.0,
        scaleX: 1.0,
        force3D: true,
        ease: 'none',
        onComplete: () => {
          bars.value[index.value].style.transform = '';
          select(index.value < bars.value.length - 1 ? index.value + 1 : 0);
        },
      },
    );
  }

  watch(index, () => {
    emit('select', index.value);
  });

  // --
  onMounted(() => {
    select(0, true);
  });

  onBeforeUnmount(() => {
    bars.value && gsap.killTweensOf(bars.value[index.value]);
  });

  // --

  const container = ref();

  useTelescope(container, {
    visible: () => select(index.value, true),
    hidden: () => gsap.killTweensOf(bars.value[index.value]),
  });

  // --

  defineExpose({ select });
</script>

<!----------------------------------------------------------------------------->

<template>
  <div ref="container" class="container">
    <span
      class="bar"
      v-for="i in items"
      @click="select(i - 1)"
      :class="{ current: i - 1 === index }"
    >
      <span class="inner">
        <span ref="bars" class="progress" :class="{ active: i - 1 < index }" />
      </span>
    </span>
  </div>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  div {
    width: 100%;
    height: px(6);

    display: flex;
    gap: px(12);

    z-index: 1;

    .bar {
      position: relative;

      width: 100%;

      cursor: pointer;
      @include hitbox(px(6), 2rem);

      &.current {
        @include blink(0.083s);
      }

      .inner {
        display: block;
        border: px(1) solid $orange;

        width: 100%;
        height: 100%;

        transition: border-color 0.11s $sineInOut;
      }

      @include hover {
        .inner {
          border-color: $white;
        }

        .progress {
          background-color: $white;
        }
      }

      .progress {
        position: absolute;

        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        background-color: $orange;
        transition: background-color 0.11s $sineInOut;

        transform: scale3d(0, 1, 1);
        transform-origin: 0 50%;
        &.active {
          transform: scale3d(1, 1, 1) !important;
        }
      }
    }
  }
</style>
