<template>
  <svg
    width="10"
    height="31"
    viewBox="0 0 10 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="28.5" width="10" height="2" fill="#777777" />
    <rect y="24.5" width="10" height="2" fill="#777777" />
    <rect y="20.5" width="10" height="2" fill="#777777" />
    <rect y="16.5" width="10" height="2" fill="#777777" />
    <rect y="12.5" width="10" height="2" fill="#777777" />
    <rect y="8.5" width="10" height="2" fill="#777777" />
    <rect y="4.5" width="10" height="2" fill="#777777" />
    <rect y="0.5" width="10" height="2" fill="#777777" />
  </svg>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  svg {
    width: px(10);
    height: px(31);
  }
</style>
