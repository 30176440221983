<script setup>
  const { data } = defineProps(['data']);
  const { title, body, image, label, weapons } = data;
  const cta = { label: data.button_title, url: data.button_link };
  const feature = { icon: data.icon, charge: data.charge };

  const shuffleWeapons = ref();
  onMounted(() => {
    shuffleWeapons.value = weapons
      .concat()
      .sort(() => Math.random() - 0.5)
      .slice(0, 3);
  });
</script>

<!----------------------------------------------------------------------------->

<template>
  <Section sectionID="nexus" :onShow="onShow" :onHide="onHide">
    <div class="content">
      <TextCard :feature="feature" :title="title" :body="body" :cta="cta" class="text-card" />
      <WeaponCard :label="label" :weapons="shuffleWeapons" class="weapon-card" />
      <img :src="useCdn().generateStaticAsset('crate.png')" />
    </div>
  </Section>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  section {
    padding: px(80) 0 px(190);

    .content {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      gap: px(24);

      .weapon-card {
        margin-top: px(104);
      }

      img {
        position: absolute;

        width: 47vw;
        height: auto;

        max-width: px(840);
        min-width: px(550);

        left: 20%;
        top: 32%;

        z-index: $layer-back;
      }
    }

    @include break-max {
      padding-bottom: px(120);
    }

    @include break-large {
      padding-bottom: px(100);

      .content {
        flex-direction: column;
        gap: 6vw;

        img {
          // display: none;
          top: 10%;
          left: 52%;
        }

        .weapon-card {
          align-self: flex-end;
          margin-top: 0;
        }
      }
    }

    @include break-medium {
      padding-bottom: px(100);
    }

    @include break-small {
      padding: px(40) 0 px(60);
      .content {
        align-items: center;

        img {
          top: -4%;
          left: 50%;

          max-width: 100%;
          min-width: 100%;

          transform: translateX(-50%);
        }

        .text-card {
          margin-top: 76vw;
        }
      }
    }

    // @include break-small {
    //   padding: px(4) 0 px(56);

    //   .content {
    //     img {
    //       width: 100%;
    //     }
    //   }
    // }

    // @include break-phone {
    //   .content {
    //     img {
    //       width: 114%;
    //     }
    //   }
    // }
  }
</style>
