<script setup>
  const { data } = defineProps(['data']);
  const { title, body, image } = data;
</script>

<!----------------------------------------------------------------------------->

<template>
  <Section sectionID="nexus">
    <div class="content">
      <img v-if="image" :src="useCdn().generatePicture(image, 'billboard').imageUrl" />
      <Text :title="title" :body="body" center />
    </div>
  </Section>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  section {
    padding: px(60) 0 px(95);

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        width: 56vw;
        height: auto;
      }
    }

    @include break-medium {
      padding: px(32) 0 px(56);
    }

    @include break-small {
      padding: px(4) 0 px(56);

      .content {
        img {
          width: 100%;
        }
      }
    }

    @include break-phone {
      .content {
        img {
          width: 114%;
        }
      }
    }
  }
</style>
