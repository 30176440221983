<template>
  <svg
    width="80"
    height="71"
    viewBox="0 0 80 71"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.48262 69.1337H5.43448V70.1203H1.48262C0.666644 70.1203 0 69.4564 0 68.6377V64.6885H0.986634V68.6377C0.986634 68.9097 1.20796 69.131 1.47995 69.131L1.48262 69.1337ZM78.5147 0H74.5655V0.986634H78.5147C78.7867 0.986634 79.008 1.20796 79.008 1.47995V5.42915H79.9947V1.48262C79.9947 0.666644 79.3307 0 78.512 0H78.5147ZM0.986634 1.48262C0.986634 1.21063 1.20796 0.9893 1.47995 0.9893H5.42915V0H1.48262C0.663978 0 0 0.663978 0 1.48262V5.43182H0.986634V1.48262ZM74.5655 7.40775V34.0042C74.5655 34.6655 74.2349 35.2815 73.6855 35.6468L66.7764 40.2547C66.0885 40.7133 65.6778 41.484 65.6778 42.3079V62.7126C65.6778 63.8032 64.7925 64.6885 63.7019 64.6885H7.40775C6.31712 64.6885 5.43182 63.8032 5.43182 62.7126V7.40775C5.43182 6.31712 6.31712 5.43182 7.40775 5.43182H72.5896C73.6802 5.43182 74.5655 6.31712 74.5655 7.40775ZM20.4793 25.5378H30.383C31.4336 25.5378 32.2869 26.3911 32.2869 27.4418V28.2044H39.1454V27.4418C39.1454 26.3911 39.9987 25.5378 41.0493 25.5378H50.953V20.13C50.953 19.5407 50.4757 19.0634 49.8863 19.0634H21.5433C20.954 19.0634 20.4767 19.5407 20.4767 20.13V25.5378H20.4793ZM17.8127 33.5375C17.8127 33.9562 18.1514 34.2975 18.57 34.3002H52.8622C53.2809 34.3002 53.6195 33.9589 53.6195 33.5375V28.2044C53.6195 27.3618 52.9369 26.6818 52.0969 26.6818H41.0493C40.628 26.6818 40.2867 27.0231 40.2867 27.4444V29.3484H31.143V27.4444C31.143 27.0231 30.8016 26.6818 30.3803 26.6818H19.3327C18.49 26.6818 17.8101 27.3644 17.8101 28.2044V33.5375H17.8127ZM53.6515 44.2039C53.6515 43.7825 53.3102 43.4412 52.8889 43.4412H50.985V35.4415H20.5086V43.4412H18.6047C18.1834 43.4412 17.8421 43.7825 17.8421 44.2039V49.537C17.8421 50.3797 18.5247 51.0596 19.3647 51.0596H30.4123C30.8336 51.0596 31.175 50.7183 31.175 50.297V48.3931H40.3187V50.297C40.3187 50.7183 40.66 51.0596 41.0813 51.0596H52.1289C52.9716 51.0596 53.6515 50.377 53.6515 49.537V44.2039Z"
      fill="#FE5C00"
    />
  </svg>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  svg {
    width: px(80);
    height: px(71);
  }
</style>
