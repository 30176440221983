<script setup>
  import CtaButtonBlock from '~/components/sections/blocks/CtaButtonBlock.vue';
  import FaqBlock from '~/components/sections/blocks/FaqBlock.vue';
  import ImageBlock from '~/components/sections/blocks/ImageBlock.vue';
  import QuoteBlock from '~/components/sections/blocks/QuoteBlock.vue';
  import TextBlock from '~/components/sections/blocks/TextBlock.vue';
  import VideoEmbedBlock from '~/components/sections/blocks/VideoEmbedBlock.vue';
  import GeneratorError from '~/components/generators/GeneratorError.vue';
  import AboutNexusSection from '~/components/sections/about/AboutNexusSection.vue';
  import CtaCardsSection from '~/components/sections/about/CtaCardsSection.vue';
  import VisionSection from '~/components/sections/about/VisionSection.vue';
  import WeaponDropSection from '~/components/sections/about/WeaponDropSection.vue';
  import RoadmapSection from '~/components/sections/about/RoadmapSection.vue';
  import FeatureTilesSection from '~/components/sections/nexus/FeatureTilesSection.vue';
  import NexusAppSection from '~/components/sections/nexus/NexusAppSection.vue';
  import CommunitySection from '~/components/sections/nexus/CommunitySection.vue';
  import WalletSection from '~/components/sections/nexus/WalletSection.vue';
  import VirtualItemsSection from '~/components/sections/nexus/VirtualItemsSection.vue';
  import AvatarHelmetsSection from '~/components/sections/universe/AvatarHelmetsSection.vue';
  import LootSection from '~/components/sections/universe/LootSection.vue';
  import InfoCardsSection from '~/components/sections/universe/InfoCardsSection.vue';

  // --

  const props = defineProps(['content']);
  const content = ref(props.content);

  const blocks = {
    cta_button: CtaButtonBlock,
    faq: FaqBlock,
    image: ImageBlock,
    quote: QuoteBlock,
    text: TextBlock,
    video_embed: VideoEmbedBlock,

    about_nexus: AboutNexusSection,
    cta_cards: CtaCardsSection,
    vision: VisionSection,
    weapon_drop: WeaponDropSection,
    roadmap: RoadmapSection,

    feature_tiles: FeatureTilesSection,
    nexus_app: NexusAppSection,
    community: CommunitySection,
    wallet: WalletSection,
    virtual_items: VirtualItemsSection,

    avatar_helmets: AvatarHelmetsSection,
    loot: LootSection,
    info_cards: InfoCardsSection,
  };
</script>

<!----------------------------------------------------------------------------->

<template>
  <component
    v-for="block in content"
    :key="block.id"
    :is="blocks[block.type] || GeneratorError"
    :data="block"
    :error="`block ${block.type} not found`"
  />
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped></style>
