<script setup>
  const { src } = defineProps(['src']);
  // --

  const modelPosition = inject('modelPosition');
</script>

<!----------------------------------------------------------------------------->

<template>
  <div>
    <SpriteAnimation class="sprite" :src="src" cols="8" rows="8" frames="64" :position="modelPosition" />
  </div>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .sprite {
    width: 100%;
    height: 100%;
  }
</style>
