<script setup>
  const { data } = defineProps(['data']);
  const { cards } = data;
</script>

<!----------------------------------------------------------------------------->

<template>
  <Section sectionID="cta" background="dark">
    <div class="content">
      <ul>
        <li v-for="(card, index) in cards">
          <Card light sharp class="card">
            <div class="container">
              <h3>{{ card.label }}</h3>
              <h2>{{ card.title }}</h2>
              <p>{{ card.description }}</p>
              <Button
                v-if="card.button_title && card.button_link"
                ref="buttons"
                :label="card.button_title"
                :to="card.button_link"
                class="button"
                :delay="index * 0.061"
              />
            </div>
          </Card>
        </li>
      </ul>
    </div>
  </Section>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  section {
    padding: px(96) 0 px(120);

    .content {
      ul {
        display: flex;
        justify-content: stretch;
        align-items: stretch;

        gap: px(20);

        li {
          width: 100%;
        }

        .card {
          padding: px(20) px(25);
          height: 100%;

          .container {
            position: relative;

            padding-bottom: px(28);
            border-bottom: px(1) solid $medium-grey;

            height: 100%;

            @include break-max {
              padding-bottom: px(92);
            }
          }
        }

        h3 {
          font-size: px(11);
          margin-bottom: px(8);
        }

        h2 {
          font-size: px(34);
          text-transform: none;
        }

        p {
          margin-top: px(20);

          @include roboto-mono-medium(px(12));
          line-height: 1.4;

          text-transform: none;

          color: $grey;

          max-width: 65%;

          @include break-max {
            max-width: px(340);
          }
        }

        .button {
          position: absolute;
          // pointer-events: none;

          bottom: px(28);
          right: 0;

          width: px(120);
        }
      }
    }

    @include break-large {
      padding: px(80) 0 px(80);
    }

    @include break-medium {
      .content {
        ul {
          flex-wrap: wrap;
          justify-content: flex-start;

          li {
            width: calc(50% - px(10));
          }
        }
      }
    }

    @include break-small {
      padding: px(60) 0;

      .content {
        ul {
          flex-direction: column;

          li {
            width: 100%;
          }
          .card {
            padding-top: px(32);
            padding-bottom: px(32);

            .container {
              display: flex;
              flex-direction: column;
              align-items: center;

              text-align: center;

              border-bottom: none;

              padding-bottom: 0;

              .button {
                position: relative;
                bottom: auto;

                margin-top: px(24);
              }
            }
          }
        }
      }
    }
  }
</style>
