<script setup>
  const { src } = defineProps(['src']);
  // --

  const container = ref();

  const scrollDirection = inject('scrollDirection');
  const scrollSpeed = inject('scrollSpeed');

  const ITERATION_DISTANCE = 1000.0;
  const IDLE = -10.0;
  const SPEED = -0.2;

  const position = ref(0.0);

  onRender(container, () => {
    position.value =
      position.value - IDLE - Math.min(0.0, scrollSpeed.value * SPEED);
  });
</script>

<!----------------------------------------------------------------------------->

<template>
  <div ref="container">
    <SpriteAnimation
      class="sprite"
      :src="src"
      cols="10"
      rows="6"
      frames="60"
      :position="position / ITERATION_DISTANCE"
    />
  </div>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .sprite {
    width: 100%;
    height: 100%;
  }
</style>
