<script setup>
  const { data } = defineProps(['data']);
  const { title, cards } = data;
</script>

<!----------------------------------------------------------------------------->

<template>
  <Section sectionID="adopter" background="dark">
    <div class="content">
      <h2>{{ title }}</h2>
      <ul>
        <li v-for="card in cards"><InfoCard :card="card" /></li>
      </ul>
    </div>
  </Section>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  section {
    padding: px(52) 0 px(70);

    .content {
      @include break-min-large {
        @include column(2, 9);
      }
    }

    ul {
      display: flex;
      gap: px(36);

      margin-top: px(52);

      li {
        width: 100%;
      }
    }
    @include break-max {
      ul {
        gap: px(12);
      }
    }

    // @include break-medium {
    //   ul {
    //     flex-wrap: wrap;

    //     li {
    //       @include break-min-small {
    //         width: calc(50% - px(8));
    //       }
    //     }
    //   }
    // }

    @include break-medium {
      h2 {
        text-align: center;
      }

      ul {
        flex-direction: column;
        align-items: center;

        gap: px(20);

        li {
          max-width: px(696);
        }
      }
    }
  }
</style>
