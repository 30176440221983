<script setup>
  const { loot } = defineProps(['loot']);

  // const weapons = loot.filter((item, index) => index > 0);

  const label = ref();

  function onMouseEnter(index) {
    label.value[index].show();
  }

  // --

  const lootIndex = ref(0);

  function select(index) {
    lootIndex.value = index; // lootcrate offset
  }

  // --

  let loop = false;
  let interval;

  onMounted(() => {
    interval = setInterval(() => {
      if (loop) select(lootIndex.value < loot.length - 1 ? lootIndex.value + 1 : 1);
    }, 2000.0);
  });

  onBeforeUnmount(() => {
    clearInterval(interval);
  });

  onResize((width) => {
    loop = width <= 1024;
  });
</script>

<!----------------------------------------------------------------------------->

<template>
  <div class="lootcrate">
    <div class="weapon">
      <img
        v-for="(weapon, index) in loot"
        :src="weapon.asset"
        :alt="weapon.name"
        :class="{ visible: index === lootIndex }"
      />
    </div>
    <ul>
      <template v-for="(weapon, index) in loot">
        <li
          @mouseenter="() => onMouseEnter(index)"
          @click="select(index)"
          :class="{ active: index === lootIndex }"
          v-show="index > 0"
        >
          <button>
            <CharacterSequence small :maxLength="3" />
            <h3><Scramble ref="label" :text="`//${weapon.name}`" /></h3>
            <img :src="weapon.asset" :alt="weapon.name" />
          </button>
        </li>
      </template>
    </ul>
  </div>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .lootcrate {
    position: relative;
    // margin-top: -3vw;

    .weapon {
      width: 35vw;
      height: 35vw;

      // margin-bottom: -6vw;

      display: flex;
      align-items: center;
      justify-content: center;

      position: absolute;
      top: 0;
      transform: translateY(-85%);

      img {
        position: absolute;
        opacity: 0;
        transition: opacity 0.2s $sineInOut;

        max-width: px(500);

        height: auto;

        &.visible {
          opacity: 1;
          transition-duration: 0.5;
        }
      }

      @include break-max {
        transform: translateY(-95%);
      }

      @include break-large {
        transform: translateY(-120%);
      }

      @include break-medium {
        position: relative;
        width: 100%;
        transform: none;
      }

      @include break-small {
        img {
          max-width: 80vw;
        }
      }
    }

    ul {
      position: relative;

      display: flex;
      justify-content: space-between;

      z-index: 1;

      gap: px(18);

      @include break-large {
        gap: px(8);
      }

      @include break-medium {
        display: none;
      }

      @include hover {
        li {
          img {
            filter: brightness(50%);
          }
        }
      }

      li {
        // min-height: px(200);
        width: 100%;

        background-color: $black;
        border: px(1) solid $dark-grey;

        &.active {
          border: px(1) solid $medium-grey;

          h3 {
            opacity: 1;
          }

          img {
            @include blink;
          }
        }

        transition: border-color 0.08s $sineInOut;

        border-radius: px(22);

        @include hover {
          border: px(1) solid $medium-grey;

          img {
            filter: none;
          }

          h3 {
            opacity: 1;
          }
        }

        button {
          padding: px(8) px(16) 0 px(16);
        }

        h3 {
          // margin-left: -px(2);
          opacity: 0.2;
        }

        img {
          object-fit: contain;

          transition: filter 0.21s $sineInOut;
        }
      }
    }
  }
</style>
