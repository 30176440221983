<script setup>
  import IconFeatureLauncher from '~/components/icons/features/IconFeatureLauncher.vue';
  import IconFeatureWallet from '~/components/icons/features/IconFeatureWallet.vue';
  import IconFeatureBackpack from '~/components/icons/features/IconFeatureBackpack.vue';
  import IconFeatureGameStudio from '~/components/icons/features/IconFeatureGameStudio.vue';
  import IconFeatureCommunity from '~/components/icons/features/IconFeatureCommunity.vue';
  import IconFeatureMarketplace from '~/components/icons/features/IconFeatureMarketplace.vue';

  // --

  const { feature, charge, large, largeOnMedium } = defineProps({
    feature: Object,
    charge: Number,
    large: Boolean,
    largeOnMedium: Boolean,
  });

  const icons = {
    launcher: IconFeatureLauncher,
    wallet: IconFeatureWallet,
    backpack: IconFeatureBackpack,
    game_studio: IconFeatureGameStudio,
    community: IconFeatureCommunity,
    marketplace: IconFeatureMarketplace,
  };
</script>

<!----------------------------------------------------------------------------->

<template>
  <div class="feature" :style="{ '--charge': charge }" :class="{ large, largeOnMedium }">
    <component :is="icons[feature.value]" class="icon" /><IconFeatureCharge class="charge" />
  </div>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .feature {
    position: relative;
    line-height: 0;

    .icon {
      max-width: px(80);
    }

    .charge {
      position: absolute;

      bottom: -px(10);
      right: -px(10);

      :deep(rect) {
        @for $i from 1 through 8 {
          &:nth-child(#{$i}) {
            opacity: calc((var(--charge) - ($i - 1)) / 4);
          }
        }
      }
    }

    &.large {
      .icon {
        width: 25vw;
        width: 22vw;
      }

      .charge {
        width: px(15);
        height: px(40);

        bottom: -px(18);
        right: -px(12);
      }
    }

    @include break-max {
      .icon {
        width: px(68);
      }

      .charge {
        width: px(8);

        bottom: -px(5);
        right: -px(8);
      }
    }

    @include break-medium {
      .icon {
        width: px(55);
      }

      &.largeOnMedium {
        @include break-min-small {
          .icon {
            width: 20vw;
            height: 20vw;
            max-width: none;
          }

          .charge {
            width: 2.5vw;
            height: 12vw;
          }
        }
      }

      .charge {
        width: px(7);

        bottom: -px(1);
        right: -px(7);
      }
    }

    @include break-small {
      .icon {
        width: 25vw;
        width: 22vw;
      }

      .charge {
        width: px(15);
        height: px(40);

        bottom: -px(18);
        right: -px(12);
      }
    }

    @include break-phone {
      .charge {
        width: px(10);
        height: px(31);

        bottom: -px(9);
        right: -px(5);
      }
    }
  }
</style>
