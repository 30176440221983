<script setup>
  const { data } = defineProps(['data']);
  const { subtitle: label, title, body, image } = data;
</script>

<!----------------------------------------------------------------------------->

<template>
  <Section sectionID="backpack" background="dark">
    <div class="content">
      <img v-if="image" :src="useCdn().generatePicture(image, 'aside').imageUrl" />
      <Text :title="title" :subtitle="subtitle" :body="body" />
    </div>
  </Section>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  section {
    padding: px(52) 0 px(70);

    .content {
      @include break-min-large {
        @include column(1, 9);
      }

      display: flex;
      justify-content: space-between;
      align-items: center;

      gap: px(32);

      img {
        width: auto;
        height: 34vw;
      }
    }

    @include break-small {
      padding: px(32) 0 px(100);

      .content {
        flex-direction: column-reverse;

        img {
          width: 120%;
          height: auto;

          width: px(480);
          margin-left: -px(272);
        }
      }
    }
  }
</style>
