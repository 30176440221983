<script setup>
  const { data } = defineProps(['data']);
  const { title, body, tiles } = data;
</script>

<!----------------------------------------------------------------------------->

<template>
  <Section sectionID="features" backgroundTab="double">
    <div class="content">
      <Text :title="title" :body="body" />
      <Features :features="tiles" class="features" />
    </div>
  </Section>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  section {
    padding: px(230) 0 px(240);

    .content {
      @include break-min-large {
        @include column(2, 9);
      }

      display: flex;
      justify-content: space-between;
      align-items: center;

      gap: px(32);
    }

    .features {
      @include break-min-small {
        margin-top: -px(28);
      }
    }

    @include break-max {
      padding: 15vw 0 16vw;
    }

    @include break-medium {
      padding: px(150) 0 px(160);
    }

    @include break-small {
      padding: px(80) 0 px(100);

      .content {
        flex-direction: column;
        gap: px(60);
      }
    }

    @include break-phone {
      .content {
        gap: px(40);
      }
    }
  }
</style>
