<template>
  <svg
    width="20"
    height="10"
    viewBox="0 0 20 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.64265 9.56967L0.443945 1.21724C0.23543 1.01802 0.223474 0.688641 0.417381 0.474815C0.611287 0.262316 0.939352 0.242395 1.15848 0.42966L9.99984 8.45807L18.8412 0.429661C18.9448 0.331381 19.0842 0.278256 19.2277 0.283566C19.3711 0.288877 19.5053 0.351302 19.6022 0.457552C19.6978 0.563802 19.7483 0.703255 19.739 0.84668C19.7297 0.990119 19.6646 1.12293 19.5557 1.21721L10.357 9.56964C10.1538 9.75292 9.84586 9.75295 9.64265 9.56967Z"
      fill="white"
    />
  </svg>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  svg {
    width: px(20);
    height: px(10);
  }
</style>
