<script setup>
  const { data } = defineProps(['data']);
  const { title, body } = data;
  const cta = { label: data.button_title, url: data.button_link };

  const lootcrate = { name: 'lootcrate', asset: useCdn().generateStaticAsset('images/lootcrate.png') };
  const loot = [
    lootcrate,
    { name: 'T.H._JK001', asset: useCdn().generateStaticAsset('images/deagle.png') },
    { name: 'Op_GRECIAN', asset: useCdn().generateStaticAsset('images/grecian.png') },
    { name: 'WAKIZASHI_DAMAS', asset: useCdn().generateStaticAsset('images/futurekatana.png') },
    { name: 'SPZ_DAMAS', asset: useCdn().generateStaticAsset('images/ghost9mm.png') },
    { name: 'S.T.Q_DEFAULT', asset: useCdn().generateStaticAsset('images/ar15.png') },
  ];
</script>

<!----------------------------------------------------------------------------->

<template>
  <Section sectionID="loot" background="dark">
    <Text :title="title" :body="body" :cta="cta" class="text" />
    <div class="content">
      <Lootcrate :loot="loot" />
    </div>
  </Section>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  section {
    padding: px(52) 0 px(70);

    .text {
      @include break-min-medium {
        @include column(7, 10);
      }

      margin-bottom: px(76);

      @include break-medium {
        align-items: center;
        text-align: center;

        max-width: px(500) !important;
      }
    }

    .content {
      @include break-min-large {
        @include column(2, 9);
      }

      @include break-medium {
      }
    }

    @include break-medium {
      .content {
        width: 100%;
      }

      &:deep(.grid) {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      // .content {
      //   display: flex;
      //   flex-direction: column;

      //   align-items: center;
      // }
    }
  }
</style>
