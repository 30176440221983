<script setup>
  const { label, avatars } = defineProps(['label', 'avatars']);

  const container = ref();

  const avatarIndex = ref(0);
  const modelPosition = ref(0.0);

  provide('modelPosition', modelPosition);

  function onSelect(index) {
    avatarIndex.value = index;
  }

  // --

  let containerX = 0.0;
  let containerWidth = 0.0;

  let targetX = 0.0;
  let mouseX = 0.0;

  onResize((width, height) => {
    const containerRect = container.value.getBoundingClientRect();

    containerX = containerRect.left;
    containerWidth = containerRect.width;

    mouseX = targetX = containerWidth * 0.42;
  });

  function onMove(e) {
    useDevice().isMobileOrTablet || (targetX = e.clientX - containerX);
  }

  function onTouchMove(e) {
    targetX = e.targetTouches[0].clientX - containerX;
  }

  onRender(container, () => {
    mouseX += (targetX - mouseX) * 0.34;

    modelPosition.value = Math.min(Math.max(mouseX / containerWidth, 0.0), 1.0);
  });
</script>

<!----------------------------------------------------------------------------->

<template>
  <Card border class="avatar-card" @mousemove="onMove">
    <CharacterSequence class="characters" :maxLength="5" />
    <div class="window" ref="container" @touchmove="onTouchMove">
      <label>//{{ label }}</label>
      <template v-for="(avatar, index) in avatars">
        <AvatarModel
          v-if="index === avatarIndex"
          :src="useCdn().generatePicture(avatar.helmet_sprite, 'webp').imageUrl"
          class="model"
        />
      </template>
      <!-- <img src="/images/avatar_grid.png" class="grid" /> -->
      <div class="glass" />
    </div>

    <div class="bottom">
      <ul>
        <template v-for="(avatar, index) in avatars">
          <li :class="{ active: index === avatarIndex }">
            <button @click="onSelect(index)">
              <img :src="useCdn().generatePicture(avatar.helmet_thumbnail, 'helmet-thumb').imageUrl" />
            </button></li
        ></template>
      </ul>
    </div>
  </Card>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .avatar-card {
    width: 100%;
    max-width: px(696);

    background-color: transparent;
    overflow: hidden;

    .characters {
      padding: px(25) px(43) px(20);
      background-color: $black;

      @include break-small {
        padding: px(25) px(28) px(20);
      }

      @include break-phone {
        padding: px(25) px(24) px(20);
      }
    }

    .window {
      position: relative;

      display: flex;
      justify-content: center;
      align-items: center;

      width: 100%;
      overflow: hidden;

      label {
        position: absolute;
        z-index: $layer-front;

        @include roboto-mono-medium(px(12));
        text-transform: none;

        top: px(22);
        left: px(22);
      }

      .model {
        position: relative;

        width: px(550);
        max-width: px(550);
        min-width: px(550);
        height: px(440);

        z-index: $layer-front;

        margin-top: -px(20);

        @include break-large {
          width: px(500);
          min-width: px(500);
          height: px(400);
        }

        @include break-small {
          width: 75vw;
          height: 60vw;

          min-width: 0;
        }
      }

      .glass {
        position: absolute;

        width: 100%;
        height: 100%;

        // background-color: $black-40;
        background-color: rgba(0, 0, 0, 0.45);

        // object-fit: contain;
      }
    }

    .bottom {
      padding: px(36) px(43) px(40);

      background-color: $black;

      @include break-small {
        padding: px(36) px(28) px(28);
      }

      @include break-phone {
        padding: px(36) px(24) px(24);
      }

      ul {
        display: flex;
        justify-content: space-between;

        border-top: px(1) solid $medium-grey;

        gap: px(12);

        height: px(240);
        padding-top: px(20);

        @include break-max {
          height: px(230);
        }

        @include break-large {
          height: px(240);
        }

        @include break-small {
          height: 32vw;
        }

        li {
          width: 100%;
          height: 100%;

          border-radius: px(20);
          border: 1px solid transparent;

          display: flex;
          align-items: center;
          justify-content: center;

          img {
            height: auto;
            pointer-events: none;
          }

          button {
            width: 100%;
            height: 100%;

            padding: 0 px(20);
          }

          &.active {
            border-color: $medium-grey;
            img {
              @include blink;
            }
          }
        }
      }
    }
  }
</style>
