<template>
  <svg
    width="80"
    height="71"
    viewBox="0 0 80 71"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.48148 69.1358H5.4321V70.1235H1.48148C0.666667 70.1235 0 69.4568 0 68.642V64.6914H0.987654V68.642C0.987654 68.9136 1.20988 69.1358 1.48148 69.1358ZM78.5185 0H74.5679V0.987654H78.5185C78.7901 0.987654 79.0123 1.20988 79.0123 1.48148V5.4321H80V1.48148C80 0.666667 79.3333 0 78.5185 0ZM0.987654 1.48148C0.987654 1.20988 1.20988 0.987654 1.48148 0.987654H5.4321V0H1.48148C0.666667 0 0 0.666667 0 1.48148V5.4321H0.987654V1.48148ZM74.5679 7.40741V34.0049C74.5679 34.6667 74.237 35.284 73.6889 35.6494L66.7802 40.2568C66.0938 40.716 65.679 41.4864 65.679 42.3111V62.716C65.679 63.8074 64.7951 64.6914 63.7037 64.6914H7.40741C6.31605 64.6914 5.4321 63.8074 5.4321 62.716V7.40741C5.4321 6.31605 6.31605 5.4321 7.40741 5.4321H72.5926C73.6839 5.4321 74.5679 6.31605 74.5679 7.40741ZM48.2074 43.0716L35.5852 30.4494L22.963 43.0716V53.8914L35.5852 41.2691L48.2074 53.8914V43.0716ZM48.2074 28.1037L35.5852 15.4815L22.963 28.1037V38.9235L35.5852 26.3012L48.2074 38.9235V28.1037Z"
      fill="#FE5C00"
    />
  </svg>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  svg {
    width: px(80);
    height: px(71);
  }
</style>
