<template>
  <svg
    width="80"
    height="71"
    viewBox="0 0 80 71"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.48262 69.1337H5.43448V70.1203H1.48262C0.666644 70.1203 0 69.4564 0 68.6377V64.6885H0.986634V68.6377C0.986634 68.9097 1.20796 69.131 1.47995 69.131L1.48262 69.1337ZM78.5147 0H74.5655V0.986634H78.5147C78.7867 0.986634 79.008 1.20796 79.008 1.47995V5.42915H79.9947V1.48262C79.9947 0.666644 79.3307 0 78.512 0H78.5147ZM0.986634 1.48262C0.986634 1.21063 1.20796 0.9893 1.47995 0.9893H5.42915V0H1.48262C0.663978 0 0 0.663978 0 1.48262V5.43182H0.986634V1.48262ZM74.5655 7.40775V34.0042C74.5655 34.6655 74.2349 35.2815 73.6855 35.6468L66.7764 40.2547C66.0885 40.7133 65.6778 41.484 65.6778 42.3079V62.7126C65.6778 63.8032 64.7925 64.6885 63.7019 64.6885H7.40775C6.31712 64.6885 5.43182 63.8032 5.43182 62.7126V7.40775C5.43182 6.31712 6.31712 5.43182 7.40775 5.43182H72.5896C73.6802 5.43182 74.5655 6.31712 74.5655 7.40775ZM48.6197 31.327H38.3534V40.66H32.7536V31.327H22.4872V51.8596C22.4872 52.8916 23.3219 53.7262 24.3539 53.7262H46.7531C47.7851 53.7262 48.6197 52.8916 48.6197 51.8596V31.327ZM51.4196 18.2607C51.4196 17.2288 50.585 16.3941 49.553 16.3941H21.5539C20.522 16.3941 19.6873 17.2288 19.6873 18.2607V25.7271C19.6873 26.7591 20.522 27.5937 21.5539 27.5937H49.553C50.585 27.5937 51.4196 26.7591 51.4196 25.7271V18.2607ZM30.087 46.7931H41.02V42.5266H30.087V46.7931Z"
      fill="#FE5C00"
    />
  </svg>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  svg {
    width: px(80);
    height: px(71);
  }
</style>
