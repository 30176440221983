<script setup>
  const { data } = defineProps(['data']);
  const { title, description, phases } = data;
</script>

<!----------------------------------------------------------------------------->

<template>
  <Section sectionID="roadmap" background="light">
    <div class="content">
      <Text center :title="title" :body="description" />
      <Roadmap :phases="phases" class="roadmap" />
    </div>
  </Section>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  section {
    padding: px(113) 0 px(55);

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .roadmap {
      margin-top: px(58);
    }

    @include break-large {
      padding: px(96) 0 px(48);
    }
  }
</style>
