<script setup>
  import AboutTemplate from '~/components/templates/AboutTemplate.vue';
  import HomeTemplate from '~/components/templates/HomeTemplate';
  import LandingTemplate from '~/components/templates/LandingTemplate.vue';
  import GeneratorError from '~/components/generators/GeneratorError.vue';
  import NexusTemplate from '~/components/templates/NexusTemplate.vue';
  import PageTemplate from '~/components/templates/PageTemplate.vue';
  import UniverseTemplate from '~/components/templates/UniverseTemplate.vue';

  // --

  const { page } = defineProps(['page']);

  const store = usePageStore();
  const handle = ref();

  handle.value = store.getBlueprintHandle;

  const templates = {
    about: AboutTemplate,
    home: HomeTemplate,
    landing: LandingTemplate,
    nexus: NexusTemplate,
    page: PageTemplate,
    universe: UniverseTemplate,
  };
</script>

<!----------------------------------------------------------------------------->

<template>
  <component :is="templates[handle]" :page="page"></component>
  <GeneratorError v-if="!templates[handle]" :error="`template ${handle} not found`" />
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped></style>
