<script setup>
  const { data } = defineProps(['data']);
  const { title, questions } = data;

  // --

  const activeQuestion = ref(0);
</script>

<!----------------------------------------------------------------------------->

<template>
  <Section sectionID="faq" background="dark">
    <div class="content">
      <h2>{{ title }}</h2>
      <ul>
        <li v-for="(question, index) in questions">
          <FAQQuestion :question="question" :active="activeQuestion === index" @click="activeQuestion = index" />
        </li>
      </ul>
    </div>
  </Section>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  section {
    padding: px(52) 0 px(70);
    // min-height: px(700);

    // @include break-small {
    // min-height: px(750);
    // }

    .content {
      @include break-min-large {
        @include column(2, 9);
      }
    }

    ul {
      display: flex;
      flex-direction: column;

      gap: px(26);

      margin-top: px(36);
    }

    @include break-medium {
      h2 {
        text-align: center;
      }
    }

    @include break-small {
      padding: px(32) 0 px(80);

      h2 {
      }
    }
  }
</style>
