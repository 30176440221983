<script setup>
  const { data } = defineProps(['data']);
  const { embed, thumbnail } = data;

  // --

  const embedRef = ref();
  let frame;

  let provider;
  let autoplay;

  onMounted(() => {
    const iframe = embedRef.value.querySelector('iframe');

    provider = iframe.dataset.provider;
    autoplay = iframe.dataset.autoplay;

    frame = iframe.contentWindow;
    autoplay === '1' && play();
  });

  // --

  const playing = ref(false);

  function onThumbClick(e) {
    playing.value || play();
  }

  // --

  function play() {
    playing.value = true;

    if (provider.includes('YouTube')) frame.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
    if (provider.includes('Vimeo')) frame.postMessage('{"method":"play"}', '*');
  }

  function pause() {
    if (provider.includes('YouTube')) frame.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
    if (provider.includes('Vimeo')) frame.postMessage('{"method":"pause"}', '*');

    playing.value = false;
  }
</script>

<!----------------------------------------------------------------------------->

<template>
  <Section>
    <div class="container">
      <div v-show="playing" ref="embedRef" v-html="embed"></div>
      <div v-show="!playing" class="thumb" @click="onThumbClick">
        <Picture v-if="thumbnail" preset="landscape-1280" :image="thumbnail" />
      </div>
    </div>
  </Section>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  section {
    .container {
      position: relative;
      height: 0;

      width: 100%;
      padding-bottom: calc(9 / 16 * 100%); // 56.25%

      cursor: pointer;

      picture {
        position: absolute;
      }

      :deep(iframe) {
        position: absolute;

        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
</style>
