<script setup>
  const api = useSatanic();
  const route = useRoute();
  const store = usePageStore();

  const slug = route.params.page === '' ? 'home' : route.params.page.slice(-1)[0];

  onServerPrefetch(async () => {
    await store.fetchPage({
      collection: 'pages',
      slug: slug,
    });
  });

  if (store.refetchPage(slug)) {
    await store.fetchPage({
      collection: 'pages',
      slug: slug,
    });
  }

  // --

  const page = ref(store.getPage);
  const previewKey = ref();

  // --

  useSeoMeta({
    robots: () => page.value?.seotamic_meta.robots,

    title: () => page.value?.seotamic_meta.title,
    description: () => page.value?.seotamic_meta.description,
    canonical: () => page.value?.seotamic_meta.canonical,

    ogType: 'website',
    ogSiteName: () => page.value?.seotamic_social.site_name,
    ogTitle: () => page.value?.seotamic_social.title,
    ogDescription: () => page.value?.seotamic_social.description,
    ogImage: () => page.value?.seotamic_social.image,
    ogUrl: () => page.value?.seotamic_meta.canonical,

    twitterCard: 'summary_large_image',
    twitterTitle: () => page.value?.seotamic_social.title,
    twitterDescription: () => page.value?.seotamic_social.description,
    twitterSite: () => '@animalabs_io',
  });

  // --

  onLivePreview(async () => {
    await store.fetchPage({
      collection: 'pages',
      slug: slug,
    });

    page.value = store.getPage;
    previewKey.value = Math.random();
  });
</script>

<!----------------------------------------------------------------------------->

<template>
  <div :class="{ locked: useDOMLock().value }">
    <TemplateGenerator :key="previewKey" :page="page"></TemplateGenerator>
  </div>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  div {
    pointer-events: none;

    &.locked {
      user-select: none;
    }
  }
</style>
