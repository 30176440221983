<script setup>
  const { data } = defineProps(['data']);
  const { title, body, image } = data;
  const cta = { label: data.button_title, url: data.button_link };
  const feature = { icon: data.icon, charge: data.charge };
</script>

<!----------------------------------------------------------------------------->

<template>
  <Section sectionID="nexus" background="dark">
    <div class="content">
      <img v-if="image" :src="useCdn().generatePicture(image, 'landscape-1280').imageUrl" />
      <TextCard :feature="feature" :title="title" :body="body" :cta="cta" />
    </div>
  </Section>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  section {
    padding: px(36) 0 px(125);

    .content {
      display: flex;
      justify-content: space-between;
      align-items: center;

      gap: px(24);

      img {
        width: 44vw;
        height: auto;
      }
    }

    @include break-large {
      padding: px(36) 0 px(108);

      .content {
        flex-direction: column;
        align-items: flex-end;

        gap: 8vw;

        img {
          width: 100%;
        }
      }
    }

    @include break-medium {
      padding: px(36) 0 px(80);
    }

    @include break-small {
      padding: px(36) 0 px(40);

      .content {
        align-items: center;
      }
    }
  }
</style>
