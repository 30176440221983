<script setup>
  const { label, weapons } = defineProps(['label', 'weapons']);

  const weaponIndex = ref(0);

  function onSelect(index) {
    weaponIndex.value = index;
  }
</script>

<!----------------------------------------------------------------------------->

<template>
  <Card border class="weapon-card">
    <CharacterSequence class="characters" :maxLength="5" />
    <div class="window">
      <label>//{{ label }}</label>
      <template v-for="(weapon, index) in weapons">
        <WeaponModel v-if="index === weaponIndex" :src="useCdn().generatePicture(weapon.weapon_sprite, 'webp').imageUrl" class="model" />
      </template>
      <!-- <img src="/images/weapon_grid.png" class="grid" /> -->
      <div class="glass" />
    </div>

    <div class="bottom">
      <template v-for="(weapon, index) in weapons">
        <div v-if="index === weaponIndex">
          <h2>
            <Scramble :text="weapon.name" />
          </h2>
          <p v-if="weapon.description">
            <Scramble :text="weapon.description" />
          </p>
        </div>
      </template>
      <ProgressBar v-if="weapons" :items="weapons.length" class="progress" @select="onSelect" />
    </div>
  </Card>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .weapon-card {
    max-width: px(696);
    width: 100%;

    background-color: transparent;
    overflow: hidden;

    .characters {
      padding: px(25) px(43) px(20);
      background-color: $black;

      @include break-small {
        padding: px(25) px(28) px(20);
      }

      @include break-phone {
        padding: px(25) px(24) px(20);
      }
    }

    .window {
      position: relative;

      display: flex;
      justify-content: center;
      align-items: center;

      width: 100%;

      label {
        position: absolute;
        z-index: $layer-front;

        @include roboto-mono-medium(px(12));
        text-transform: none;

        top: px(22);
        left: px(22);
      }

      .model {
        position: relative;

        width: px(460);
        max-width: px(460);
        height: px(460);

        z-index: $layer-front;

        margin-top: -px(20);
        margin-bottom: -px(20);

        @include break-small {
          width: 75vw;
          height: 75vw;
        }
      }

      .glass {
        position: absolute;

        width: 100%;
        height: 100%;

        background-color: $black-40;

        // object-fit: contain;
      }
    }

    .bottom {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      padding: px(36) px(43) px(27);
      height: px(287);

      @include break-small {
        padding: px(36) px(28) px(28);
        height: px(320);
      }
      @include break-phone {
        padding: px(36) px(24) px(24);
        height: px(360);
      }

      h2 {
        white-space: nowrap;
      }

      background-color: $black;

      p {
        color: $grey;

        @include roboto-mono-medium(px(12));
        text-transform: none;

        padding: px(22) 0 0;
      }

      .progress {
        position: relative;

        &:before {
          content: '';

          position: absolute;

          left: 0;
          top: -px(38);

          height: px(1);
          width: 100%;

          background-color: $grey;
        }
      }
    }
  }
</style>
